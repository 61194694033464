html, body {
	position: relative;
	width: 100%;
	height: 100%;
	font-style: normal;
  font-family: 'Inconsolata', monospace;
}

body {
	box-sizing: border-box;
	position: relative;
	background: #0f0f0f;
	color: #FFFFFF;
}

h1 {
	font-weight: 600;
	font-size: 48px;
	line-height: 50px;
}

h2 {
	font-weight: 600;
	font-size: 48px;
	line-height: 50px;
}

h3 {
	font-weight: 400;
	font-size: 28px;
	line-height: 29px;
	color: #C56400;
}

h4 {
	font-weight: 400;
	font-size: 24px;
	line-height: 25px;
}

h5 {
	font-weight: 400;
	font-size: 18px;
	line-height: 19px;
}

h6 {
	font-weight: 400;
	font-size: 16px;
	line-height: 17px;
}

button {
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;

  border: none;
}

.btn {
  background: #F08625;
  border-radius: 4px;

  background: none;
	color: #C56400;
}

.btn-orange{
  color: #000002;
  background: #F08625;
  border-radius: 4px;
}

.btn-dark{
  background: #343434;
  border-radius: 4px;

  color: #DEDEDE;
}
